import debounce from 'lodash/debounce'
import { useLibrariesStore } from '@/store/libraries'

export const useGetPartners = debounce(
  async (force?: boolean) => {
    const librariesStore = useLibrariesStore()
    if (librariesStore.getPartners?.length && !force) {
      return librariesStore.getPartners
    } else {
      const { data, error } = await useAPIFetch(`finance/deals/partners `)
      if (error.value) throw createError(error.value)
      const partners = data?.value?.data || []
      librariesStore.setPartners(partners)
      return librariesStore.getPartners
    }
  },
  200,
  { leading: true, trailing: false }
)
