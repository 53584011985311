import type { ApiResponse, Tag } from '@/types'
import { useLibrariesStore } from '@/store/libraries'

export const useGetDealTags = async () => {
  const librariesStore = useLibrariesStore()

  if (librariesStore.getDealTags?.length) {
    return librariesStore.getDealTags
  } else {
    const { data, error } = await useAPIFetch(`/tags`, {
      params: { entity: 'deal' },
    })
    if (error.value) throw createError(error.value)
    const library = ((data?.value as ApiResponse)?.data as Tag[]) || []
    librariesStore.setTagsDeal(library)
    return library
  }
}

export const useGetTags = async () => {
  const librariesStore = useLibrariesStore()

  if (librariesStore.getTags?.length) {
    return librariesStore.getTags
  } else {
    const { data, error } = await useAPIFetch(`/tags`)
    if (error.value) throw createError(error.value)
    const library = ((data?.value as ApiResponse)?.data as Tag[]) || []
    librariesStore.setTags(library)
    return library
  }
}
